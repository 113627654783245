<template>
  <v-row>
    <v-col>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        disable-sort
        class="elevation-1"
        :footer-props="{ itemsPerPageText: 'Filas por página:' }"
        :items-per-page="-1"
      >
        <template v-slot:top>
          <v-card-title>
            <v-toolbar flat>
              <v-icon>mdi-medical-bag</v-icon>
              <v-divider class="mx-4" vertical></v-divider>
              <v-spacer style="position: relative; top: 12px">
                <v-row>
                  <v-col>
                    <v-select
                      no-data-text="Sin datos disponibles"
                      :items="selectitems1"
                      dense
                      outlined
                      v-model="select1"
                      label="Selecciona una empresa"
                      item-text="nombre_empresa"
                      item-value="rfc_empresa"
                      @change="gettabla"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-spacer>
            </v-toolbar>
          </v-card-title>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon title="Documentos" @click="documentos(item.rfc)">
            mdi-file-document-multiple-outline
          </v-icon>
          <v-icon title="Agregar" @click="agregar(item.rfc, item.nombre)">
            mdi-text-box-plus
          </v-icon>
        </template>
        <template v-slot:no-data>
          <br />
          <p>Sin datos disponibles</p>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogtable" persistent max-width="1000">
        <v-card>
          <v-card-title class="headline">
            <v-select
              no-data-text="Sin datos disponibles"
              :items="selectitems2"
              v-model="select2"
              dense
              outlined
              label="Selecciona un tipo de documento"
              @change="gettabla2"
            ></v-select>
          </v-card-title>
          <v-card-text class="text-h6">
            <v-data-table
              dense
              :headers="headerstable"
              :items="itemstable"
              disable-sort
              class="elevation-0"
              hide-default-footer
              :items-per-page="-1"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon title="Mostrar" @click="singledocument(item)">
                  mdi-eye
                </v-icon>
                <v-icon title="Descargar" @click="downdocument(item)">
                  mdi-download
                </v-icon>
              </template>
              <template v-slot:no-data>
                <br />
                <p>No hay documentos disponibles</p>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="closetable"> Aceptar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogpdf" persistent max-width="1000">
        <v-card>
          <br />
          <v-card-text class="text-h6">
            <embed
              :src="source"
              width="100%"
              height="500px"
              type="application/pdf"
            />
          </v-card-text>
          <v-card-actions dense>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogpdf = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogagregar" hide-overlay persistent width="800">
        <v-card>
          <v-card-title>
            <span class="text-h5">Agregar documento:</span>
          </v-card-title>
          <v-card-title>
            <span class="text-h6">{{ name }} ({{ rfc }})</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="formadd">
                <v-row>
                  <v-col cols="6">
                    <v-select
                      :rules="[rules.required]"
                      :items="anios"
                      v-model="add.anio"
                      label="Año"
                      dense
                      small
                      outlined
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :rules="[rules.required]"
                      :items="meses"
                      v-model="add.mes"
                      label="Mes"
                      dense
                      small
                      outlined
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :rules="[rules.required]"
                      :items="tipos"
                      v-model="add.tipo"
                      label="Tipo de reporte"
                      dense
                      small
                      outlined
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-file-input
                      id="filepdf"
                      accept="application/pdf"
                      @change="uploadpdf()"
                      label="Documento"
                      outlined
                      small
                      :rules="specialrulepdf"
                      dense
                      show-size
                      small-chips
                      prepend-inner-icon=""
                      append-icon=""
                      :clearable="false"
                      v-model="modelpdf"
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="closeadd"> Cancelar </v-btn>
            <v-btn color="program" text @click="saveadd"> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <div class="under" id="insertar"></div>
  </v-row>
</template>

<style scoped>
.under {
  z-index: -10;
  padding: 0;
  margin: 0;
}
</style>

<script>
import axios from "axios";
import App from "../App.vue";
export default {
  components: { App },
  data: () => ({
    defaultfile: {
      file: null,
      index: 0,
      multiple: false,
      text: "",
      name: "Archivo en revisión",
    },
    source: "",
    rfc: "",
    name: "",
    add: {
      anio: "",
      mes: "",
      tipo: "",
    },
    modelpdf: null,
    anios: ["2023"],
    meses: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    tipos: [],
    Message: "Internal Error",
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    dialogtable: false,
    dialogpdf: false,
    dialogagregar: false,
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id_usuariotrabajador",
      },
      { text: "Nombre", value: "nombre", align: "center" },
      { text: "RFC", value: "rfc", align: "center" },
      { text: "NSS", value: "nss", align: "center" },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],
    headerstable: [
      {
        text: "ID",
        align: "start",
        value: "id_documento",
      },
      { text: "RFC", value: "rfc_trabajador", align: "center" },
      { text: "Nombre", value: "nombre_documento", align: "center" },
      { text: "Año", value: "anio_documento", align: "center" },
      { text: "Mes", value: "mes_documento", align: "center" },
      { text: "Fecha", value: "fecha_carga", align: "center" },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    itemstable: [],
    selectitems1: [],
    selectitems2: [],
    select1: "",
    select2: "",
    rules: {
      required: (value) => !!value || "Requerido",
    },
  }),
  computed: {
    specialrulepdf() {
      const rules = [];
      const ruler = (v) => this.modelpdf != null || "Requerido";
      rules.push(ruler);
      const ruler2 = (v) =>
        this.modelpdf != this.defaultfile || "Sube de nuevo el archivo";
      rules.push(ruler2);
      return rules;
    },
  },
  methods: {
    getlista() {
      let vue = this;
      vue.dialogIni = true;
      axios
        .post("api/doctores/ListaEmpresas")
        .then(function (response) {
          vue.dialogIni = false;
          vue.selectitems1 = response.data;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data.Message;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    gettabla() {
      let vue = this;
      vue.dialogIni = true;
      var Params = {
        baserfc: vue.select1,
      };
      axios
        .post("api/doctores/ListaTrabajadores", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.items = response.data;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data.Message;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    documentos(rfc) {
      let vue = this;
      vue.rfc = rfc;
      vue.dialogIni = true;
      var Params = {
        baserfc: "lav1401142m6",
        rfc: vue.rfc,
      };
      axios
        .post("api/doctores/ListaTiposTrabajadores", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.selectitems2 = response.data;
          vue.dialogtable = true;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          console.log(error);
          vue.selectitems2 = [];
          vue.dialogtable = true;
        });
    },
    agregar(rfc, name) {
      let vue = this;
      vue.rfc = rfc;
      vue.name = name;
      vue.dialogIni = true;
      axios
        .post("api/Trabajadores/Consulta_Doc_gral")
        .then(function (response) {
          vue.dialogIni = false;
          for (var i = 0; i < response.data.length; i++) {
            vue.tipos.push(response.data[i].tipo_documento);
          }
          vue.dialogagregar = true;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          console.log(error);
          vue.tipos = [];
          vue.Message =
            "No se ha recibido los tipos de documentos, revise su conexión";
          vue.dialog404 = true;
        });
    },
    saveadd() {
      let vue = this;
      vue.dialogIni = true;
      var Params = {
        rfc_trabajador: vue.rfc,
        rfc_empresa: vue.select1,
        tipo_documento: vue.add.tipo,
        anio: vue.add.anio,
        mes: vue.add.mes,
        nombre_documento: vue.modelpdf.name.substr(0, vue.modelpdf.name.length - 4),
        documento: vue.filepdf.substr(28),
      };
      axios
        .post("api/Trabajadores/CargaDocumentos", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.dialogagregar = false;
          console.log(response.data);
          vue.gettabla();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data.Message;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    closeadd() {
      this.add = {
        anio: "",
        mes: "",
        tipo: "",
      };
      this.modelpdf = null;
      this.dialogagregar = false;
    },
    uploadpdf() {
      this.dialogIni = true;
      setTimeout(() => {
        const file = document.getElementById("filepdf").files[0];
        if (file != null) {
          const reader = new FileReader();
          var vue = this;
          reader.onloadend = () => {
            this.filepdf = reader.result;
          };
          reader.readAsDataURL(file);
        } else {
          this.filepdf = "";
        }
        this.dialogIni = false;
      }, 100);
    },
    gettabla2() {
      let vue = this;
      vue.dialogIni = true;
      var Params = {
        baserfc: vue.select1,
        rfc: vue.rfc,
        tipo: vue.select2,
      };
      console.log(Params);
      axios
        .post("api/doctores/ListaDocumentosTrabajadores", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.itemstable = response.data;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data.Message;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    singledocument(item) {
      let vue = this;
      vue.source = "";
      vue.dialogIni = true;
      var Params = {
        id_documento: item.id_documento,
        rfc_trabajador: item.rfc_trabajador,
        tipo_documento: item.tipo_documento,
        nombre_documento: item.nombre_documento,
        anio_documento: item.anio_documento,
        mes_documento: item.mes_documento,
      };
      axios
        .post("api/doctores/RecuperaPdf", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.source = "data:application/pdf;base64," + response.data.archivo;
          vue.dialogpdf = true;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data.Message;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.source = "";
        });
    },
    downdocument(item) {
      let vue = this;
      vue.dialogIni = true;
      var Params = {
        id_documento: item.id_documento,
        rfc_trabajador: item.rfc_trabajador,
        tipo_documento: item.tipo_documento,
        nombre_documento: item.nombre_documento,
        anio_documento: item.anio_documento,
        mes_documento: item.mes_documento,
      };
      axios
        .post("api/doctores/RecuperaPdf", Params)
        .then(function (response) {
          vue.dialogIni = false;
          var a = document.createElement("a");
          a.href = "data:application/pdf;base64," + response.data.archivo;
          a.download = "Healthme.pdf";
          a.click();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data.Message;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    closetable() {
      this.dialogtable = false;
      this.select2 = "";
      this.selectitems2 = [];
      this.itemstable = [];
    },
  },
  mounted() {
    this.$store.dispatch("autologin");
    this.getlista();
  },
};
</script>