import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import router from '../router/index'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        usuario: null,
        pass: null,
        nombre: null
    },
    mutations: {
        setUsuario(state, usuario) {
            state.usuario = usuario
        },
        setPass(state, pass) {
            state.pass = pass
        },
        setNombre(state, nombre) {
            state.nombre = nombre
        }
    },
    actions: {
        guardarusuario({ commit }, usuario) {
            commit("setUsuario", usuario)
            localStorage.setItem("usuario", usuario)
        },
        guardarpass({ commit }, pass) {
            commit("setPass", pass)
            localStorage.setItem("pass", pass)
        },
        guardarnombre({ commit }, nombre) {
            commit("setNombre", nombre)
            localStorage.setItem("nombre", nombre)
        },
        login({ commit }) {
            let u = localStorage.getItem("usuario")
            let p = localStorage.getItem("pass")
            var Params = {
                usuario: u,
                pass: p,
            };
            axios
                .post("api/doctores/Login", Params)
                .then(function(response) {
                    commit("setNombre", response.data.nombre)
                    localStorage.setItem("nombre", response.data.nombre)
                    commit("setUsuario", response.data.usuario)
                    localStorage.setItem("usuario", response.data.usuario)
                    commit("setPass", response.data.pass)
                    localStorage.setItem("pass", response.data.pass)
                    location.href = "menu";
                })
                .catch(function(error) {
                    console.log(error);
                    commit("setNombre", null)
                    commit("setUsuario", null)
                    commit("setPass", null)
                    localStorage.removeItem("nombre")
                    localStorage.removeItem("usuario")
                    localStorage.removeItem("pass")
                });
        },
        autologin({ commit }) {
            let u = localStorage.getItem("usuario")
            let p = localStorage.getItem("pass")
            var Params = {
                usuario: u,
                pass: p,
            };
            axios
                .post("api/doctores/Login", Params)
                .then(function(response) {
                    commit("setNombre", response.data.nombre)
                    localStorage.setItem("nombre", response.data.nombre)
                    commit("setUsuario", response.data.usuario)
                    localStorage.setItem("usuario", response.data.usuario)
                    commit("setPass", response.data.pass)
                    localStorage.setItem("pass", response.data.pass)
                })
                .catch(function(error) {
                    console.log(error);
                    commit("setNombre", null)
                    commit("setUsuario", null)
                    commit("setPass", null)
                    localStorage.removeItem("nombre")
                    localStorage.removeItem("usuario")
                    localStorage.removeItem("pass")
                    location.href = "login";
                });
        },
        appsalir({ commit }) {
            commit("setNombre", null)
            commit("setUsuario", null)
            commit("setPass", null)
            localStorage.removeItem("nombre")
            localStorage.removeItem("usuario")
            localStorage.removeItem("pass")
            location.href = "login";
        },
    },
})