<template>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({}),
  methods: {
    login() {
      location.href = "login";
    },
  },
  created() {
    this.login();
  }
};
</script>