<template>
  <v-app>
    <v-card class="mx-auto overflow-hidden" tile height="100%" width="100%">
      <v-app-bar dense color="program" dark>
        <template v-if="pagina == 'Menu'">
          <v-col cols="12" xs="12">
            <v-row>
              <v-app-bar-nav-icon
                dense
                title="Menú"
                @click.stop="drawer = !drawer"
              >
                <v-img
                  src="@/assets/logo_contago.png"
                  transition="scale-transition"
                  max-height="50"
                  max-width="50"
                />
              </v-app-bar-nav-icon>
              <v-spacer style="display: flex; min-width: 1%" />
              <v-btn title="Cerrar Sesión" @click="dialog = true" icon>
                <v-icon large>mdi-logout-variant</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </template>
      </v-app-bar>

      <template v-if="pagina == 'Menu'">
        <v-navigation-drawer v-model="drawer" absolute temporary app clipped>
          <v-list>
            <v-list-item :to="{ path: '/menu' }">
              <v-list-item-action>
                <v-icon>mdi-home</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Trabajadores</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </template>

      <v-main>
        <v-container fluid>
          <v-fade-transition mode="out-in">
            <router-view></router-view>
          </v-fade-transition>
        </v-container>
      </v-main>
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="350">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text class="text-h6">
          <h5>¿Desea cerrar la sesión?</h5>
        </v-card-text>
        <v-card-actions>
          <v-btn color="program" text @click="dialog = false">
            <h4>Cancelar</h4>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="program" text @click="appsalir">
            <h4>Aceptar</h4>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog500" max-width="350">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-h6">
          <h5>Problemas de conexión</h5>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="program" text @click="dialog500 = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    dialog: false,
    dialog500: false,
    drawer: false,
    group: null,
    messages: 0,
  }),

  computed: {
    pagina() {
      var a = this.$router.history.current.name;
      if (a == "NotFound" || a == "Inicio" || a == "Home") {
        return "Home";
      } else if (a == "Login") {
        return "Login";
      } else {
        return "Menu";
      }
    },
  },

  methods: {
    appsalir() {
      this.dialog = false;
      this.$store.dispatch("appsalir");
    },
  },

  /*
  Error AXIOS
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  */
};
</script>
