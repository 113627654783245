import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'

//Vue.config.productionTip = false;
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//axios.defaults.baseURL = "https://localhost:44386/";
axios.defaults.baseURL = "https://healthme.contago.com.mx/";

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')